<template>
	<div class="flexPage darkPage">
		<navbar :params="pageInfo">
			<template #right>
				<img src="../../assets/img/record.png" alt="" style="width: 24px;height: 24px;">
			</template>
		</navbar>
		<div class="content">
			<div class="cellGroup">
				<div class="tbTitle1">{{ $t('other.translate72') }}</div>
				<div style="display: flex;align-items: center;margin-top: 8px;">
					<img src="../../assets/img/USDT.png" alt="" style="width: 32px;height: 32px;margin-right: 8px;">
					<span class="usdtName">USDT - ERC20</span>
				</div>
			</div>
			<div class="from">Transfer from-to</div>
			<!-- 转换 -->
			<div class="justify-between">
				<div class="flex-one">
					<div class="bz-text">
						<div class="leave-for">{{ $t('form.assets.from') }}</div>
						<template v-if="!swapPosition">
							<span v-if="toptype == 'otc'" class="account">{{ $t('assets.index.otc_account') }}</span>
							<span v-else-if="toptype == 'contract'" class="account">{{
								$t('assets.index.contract_account')
							}}</span>
							<span v-else class="account">{{ $t('assets.index.a1') }}</span>
						</template>
						<template v-else>
							<div v-if="toptype == 'bibi'">
								<span v-if="value == 'otc'" class="account">{{ $t('assets.index.otc_account') }}</span>
								<span v-else-if="value == 'contract'" class="account">{{
									$t('assets.index.contract_account')
								}}</span>
							</div>
							<span v-else class="account">{{ $t('assets.index.a1') }}</span>
						</template>
					</div>
					<div class="bz-text">
						<div class="leave-for">{{ $t('form.assets.to') }}</div>
						<template v-if="!swapPosition">
							<div v-if="toptype == 'bibi'">
								<span v-if="value == 'otc'" class="account">{{ $t('assets.index.otc_account') }}</span>
								<span v-else-if="value == 'contract'" class="account">{{
									$t('assets.index.contract_account')
								}}</span>
							</div>
							<span v-else class="account">{{ $t('assets.index.a1') }}</span>
						</template>
						<template v-else>
							<span v-if="toptype == 'otc'" class="account">{{ $t('assets.index.otc_account') }}</span>
							<span v-else-if="toptype == 'contract'" class="account">{{
								$t('assets.index.contract_account')
							}}</span>
							<span v-else class="account">{{ $t('assets.index.a1') }}</span>
						</template>
					</div>
					<img src="../../assets/img/choose.png" alt="" @click="onShift"
						style="width: 48px;height: 48px;position: absolute;top: 67px;left: 161px;">
				</div>
				<!-- <img @click="onShift" style="width: 7.6336vw;height: 7.6336vw;" src="@/assets/img/shift.png" alt=""> -->
			</div>
			<!-- 体验金和代币切换 -->
			<!-- <div v-if="toptype == 'bibi' || toptype == 'contract'" class="meansExchange">
				<div :class="exchangeTable === 1 ? 'activeTab' : ''" class="left" @click="exchangeCut(1)">
					{{ $t('assets.index.Tokens') }}</div>
				<div :class="exchangeTable === 2 ? 'activeTab' : ''" class="right" @click="exchangeCut(2)">{{
					$t('assets.index.trial_fee')
				}}</div>
			</div> -->
			<!-- 提取数量输入框 -->
			<div>
				<span style="display: inline-block;margin-top: 24px;">{{ $t('trade.number') }}</span>
				<van-field v-model.number="transferAmount" type="number" min="0" @input="() => {
					if (transferAmount < 0 || transferAmount == '') transferAmount = 0;
				}
					">
					<template #right-icon>
						USDT
					</template>
				</van-field>
			</div>
			<!-- 可用资产 -->
			<div v-if="toptype == 'bibi' || toptype == 'contract'"
				style="width: 100%;display: flex;justify-content: flex-end;color: #00000080">
				<span>{{ $t('trade.use') }}: </span>
				<span class="balance">{{ info.balance }}</span>
			</div>
			<div v-else>
				<span>{{ $t('trade.use') }}</span>
				<span class="balance">{{ swapPosition ? balance : info.balance }}</span>
			</div>
		</div>
		<van-action-sheet v-model="sheetShow" @select="selectSheet" :actions="transferOptions"
			:cancel-text="$t('button.otc.cancel')" close-on-click-action />
		<div class="p_absolute">
			<van-tabbar>
				<van-tabbar-item>
					<van-button
						style="width: 343px;height: 44px;background-color: #000000;color: #fff;border-radius: 8px;"
						@click="sure">{{ $t('button.assets.sure') }}</van-button>
				</van-tabbar-item>
			</van-tabbar>
		</div>
	</div>
</template>
<script>
import navbar from "@/views/modules/navbar.vue";
import store from "./store/common";
export default {
	data() {
		return {
			pageInfo: {
				customTitle: this.$t('assets.index.n3'),
				url: '/transferHistory',
				value: '',
				icon: 'orders-o',
				navbarStyle: 'normal'
			},
			info: {},
			toptype: store.state.topType,
			exchangeTable: 1,
			transferAmount: 0,
			sheetShow: false,
			value: 'contract',
			bibiToTest: '',
			balance: 0,
			swapPosition: false, // 控制元素位置交换
			transferOptions: [
				{
					name: this.$t('assets.index.otc_account'),
					value: 'otc'
				},
				{
					name: this.$t('assets.index.contract_account'),
					value: 'contract'
				}
			],
		}
	},
	components: { navbar },
	computed: {
		currentBalance() {
			return this.info.trialfundsBalance - 100 < 0 ? 0 : this.info.trialfundsBalance - 100;
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.info = store.state.currency;
		});
	},
	methods: {
		exchangeCut(type) {
			this.exchangeTable = type;

		},

		getAcquisitionAssets(currency) {
			this.$http.post(this.host + '/uc/asset/spotWallet/' + currency).then((res) => {
				if (res.body.code == 0) {
					this.balance = res.body.data.balance;
				} else {
					this.$toast(res.body.message);
				}
			});
		},

		onShift() {
			// 切换元素位置
			this.swapPosition = !this.swapPosition;
			if (this.swapPosition) {
				this.getAcquisitionAssets(this.info.coin.name);
			}
		},
		sure() {
			if (this.transferAmount <= 0) return this.$toast('当前不可划转');
			console.log(this.toptype);
			if (this.toptype == 'otc') {
				const params = {
					coinName: this.info.coin.name,
					amount: this.transferAmount,
					direction: !this.swapPosition ? 1 : 0
				};
				this.bibiToOtc(params);
			} else if (this.toptype == 'contract') {
				const params = {
					coinName: this.info.coin.name,
					amount: this.transferAmount,
					direction: !this.swapPosition ? 1 : 0
				};
				if (this.exchangeTable === 2) {
					this.contractWithdraw(1);
				} else {
					this.bibiToContract(params);
				}
			} else if (this.toptype == 'bibi') {
				const params = {
					coinName: this.info.coin.name,
					amount: this.transferAmount,
					direction: !this.swapPosition ? 0 : 1
				};
				if (this.exchangeTable === 2) {
					if (this.value === '') {
						return this.$toast('请选择otc');
					}
					if (this.value === 'otc') {
						this.withdrawTrailFunds();
					} else {
						// 合约账户提取
						this.contractWithdraw(0);
					}
				} else {
					if (this.bibiToTest == 'contract') {
						this.bibiToContract(params);
					} else {
						this.bibiToOtc(params);
					}
				}
			}
		},
		// OTC划转
		bibiToOtc(params) {
			this.$http.post(this.host + '/uc/otc/wallet/transfer', params).then((res) => {
				if (res.body.code == 0) {
					this.$toast('操作成功');
					this.transferAmount = 0;
				} else {
					this.$toast(res.body.message);
					this.transferAmount = 0;
				}

				this.$router.push({ name: 'assets' });
			});
		},
		withdrawTrailFunds() {
			// 提取体验金otc
			if (this.transferAmount === '') {
				return this.$toast('请输入数量');
			}
			if (this.transferAmount > this.currentBalance) {
				return this.$toast(`可划转数量不足`);
			}
			const params = {
				coinName: this.coin,
				amount: this.transferAmount
			};
			this.$http.post(this.host + '/uc/otc/wallet/withdrawTrailFunds', params).then((res) => {
				if (res.body.code == 0) {
					this.$toast('操作成功');
					this.exchangeTable = 1;
					this.transferAmount = 0;
				} else {
					this.$toast(res.body.message);
					this.exchangeTable = 1;
					this.transferAmount = 0;
				}

				this.$router.push({ name: 'assets' });
			});
		},
		contractWithdraw(type) {
			// 提取体验金  type === 0币币---》合约
			if (this.transferAmount === '') {
				return this.$toast('请输入数量');
			}
			const params = {
				coinName: this.info.coin.name,
				amount: this.transferAmount,
				direction: type
			};
			this.$http.post(this.host + '/uc/asset/transferContractTrail', params).then((res) => {
				if (res.body.code == 0) {
					this.$toast('操作成功');
					this.exchangeTable = 1;
					this.transferAmount = 0;
				} else {
					this.$toast(res.body.message);
					this.exchangeTable = 1;
					this.transferAmount = 0;
				}
			});
		},
		// 合约划转
		bibiToContract(params) {
			this.$http.post(this.host + '/uc/asset/transferContract', params).then((res) => {
				if (res.body.code == 0) {
					this.$toast('操作成功');
					this.transferAmount = 0;
				} else {
					this.$toast(res.body.message);
					this.transferAmount = 0;
				}
			});
		},
		// 选择器
		selectSheet(val) {
			this.value = val.value;
			this.bibiToTest = val.value;
		}
	}
}
</script>
<style scoped>
::v-deep .van-cell {
	width: 109% !important;
	margin-left: -16px !important;
	border: none;
}

::v-deep .van-field__control {
	border: none;
	border-bottom: 2px solid #D9D9D9;
}

.from {
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 19.6px;
	letter-spacing: -0.02em;
	margin: 48px 0 8px 0
}

.p_absolute .van-tabbar {
	height: 20.3562vw;
}

.cellGroup .tbTitle1 {
	min-width: 22.9008vw;
	color: #0000004D
}

.usdtName {
	font-family: Inter;
	font-size: 20px;
	font-weight: 600;
	line-height: 28px;
}

.p_absolute .van-tabbar-item .van-tabbar-item__text {
	width: 90vw;
}

.content {
	width: 100%;
	margin-top: 5.0891vw;
	padding: 0 16px;
	box-sizing: border-box;
}

.bz-text {
	font-size: 3.5623vw;
	color: #616E85;
	width: 100%;
	height: 88px;
	background-color: #F6F6F6;
	border-radius: 16px;
	margin-bottom: 8px;
	padding-left: 16px;
	overflow: hidden;
}

.bz-text .balance {
	margin-left: 6.1069vw;
}

.bz-text .leave-for {
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 19.6px;
	letter-spacing: -0.02em;
	color: #808080;
	margin: 16px 0
}

.bz-text .account,
.bz-text .select-boxes .account {
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	color: #000
}

.mt10 {
	margin-top: 2.5445vw;
}

.mt15 {
	margin-top: 3.8168vw;
}

.bz-text .van-cell {
	padding: unset;
}

.bz-text .van-cell .van-cell__value .van-field__body .van-field__control {
	margin-bottom: unset !important;
}

.meansExchange {
	display: flex;
	margin-left: 12.7226vw;
	font-size: 4.0712vw;
	padding-top: 5.0891vw;
	padding-bottom: 5.0891vw;
	box-sizing: border-box;
}

.left {
	padding: .7634vw 4.0712vw;
	border-radius: 2.0356vw;
}

.right {
	margin-left: 5.0891vw;
	padding: .7634vw 4.0712vw;
	border-radius: 2.0356vw;
}

.activeTab {
	background: #3861FB;
	color: #FFFFFF;
}

.select-boxes {
	margin-left: 1.2723vw;
	line-height: 5.3435vw;
	width: 40%;
	height: 10.1781vw;
	padding: 2.5445vw 3.8168vw;
	border: 1px solid rgba(0, 0, 0, .2);
	border-radius: .7634vw;
	outline: none;
	background-color: #fff;
	box-sizing: border-box;
}

.float-right {
	float: right;
}

.flex-center {
	display: flex;
	align-items: center;
}

.justify-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.flex-one {
	width: 100%;
	position: relative;
}

.line {
	margin: 2.5445vw 0;
	border: 1px solid #EFF0F2;
}

.swap-enter-active,
.swap-leave-active {
	transition: all 0.5s;
}

.swap-enter,
.swap-leave-to {
	opacity: 0;
}
</style>
